import styled from "styled-components"

const ProjectSectionStyles = styled.div`
  min-height: 70vh;
  .project-section-title {
    margin-top: 4vh;
    color: #FFF;
    font-size: 2rem;
    width: 60vw;
    min-width: 300px;
    text-align: center;
     @media only screen and (max-width: 768px) {
        width: 100vw;
     }
  }
  .project-section-detail {
    color: #FFF;
    display: flex;
    justify-content: center;
    align-items: center;
    //flex-wrap: wrap;
    margin: 4vh 8vw 2vh 8vw;
    
    @media only screen and (max-width: 768px) {
      flex-direction: column;
    }
 
    .project-section-detail-textblock {
       width: 58vw;
       min-height: 100%;
       line-height: 2rem;
       vertical-align: center;
       display: flex;
       flex-direction: column;
       justify-content: space-between;
       padding: 0 2vw 0 0;
 
       @media only screen and (max-width: 768px) {
         width: 90vw;
         padding: 0;
         order: 2
       }
       
       @media only screen and (min-width: 769px) {
         font-size: 1.5rem;
       }
       
       * {
         margin: 0 0 5vh 0;
       }
    }
    .project-section-picture {
      border-radius: 3px;
      
      @media only screen and (max-width: 768px) {
        width: 80vw;
        height: 80vw;
        margin-bottom: 4vh;
        order: 1;
      }
      
      @media only screen and (min-width: 769px) {
        width: 30vw;
        height: 30vw;
      }
    }
  }
  .project-section-more {
    margin: 0 auto 4vh auto;
    width: 100%;
    text-align: center;
    color: #FFF;
    text-decoration: underline;
    
    @media only screen and (min-width: 769px) {
      font-size: 1.5rem;
    }
  }
`
export default ProjectSectionStyles