import React from "react"
import Layout from "../components/Layout/Layout"
import LayoutStyles from "../components/LayoutStyles/LayoutStyles"
import StyledBackgroundImageFull from "../components/BackgroundImageFull/BackgroundImageFull"
import SectionTransition from "../components/SectionTransition/SectionTransition"
import ScrollNudge from "../components/ScrollNudge/ScrollNudge"
import ProjectSection from "../components/ProjectSection/ProjectSection"
import ProjectSectionTextBlock from "../components/ProjectSection/ProjectSectionTextBlock/ProjectSectionTextBlock"
import ProjectSectionPicture from "../components/ProjectSection/ProjectSectionPicture/ProjectSectionPicture"
import { Helmet } from "react-helmet"

const Projects = () => {
  return (
    <div>
      <Layout>
        <Helmet>
          <title>Projects | Alan Henson - Freelance Full-Stack Engineer</title>
        </Helmet>
        <LayoutStyles>
          <StyledBackgroundImageFull imgName={'cliffAndPond'} />
          <div className={'above-fold-container'}>
            <div className={'gradient-billboard'} style={{color: '#FFF'}} >
              <div style={{marginTop: '22vh'}} className={'typography-main'}>Projects</div>
            </div>
            <SectionTransition to={'#5F6260'} static>
              <ScrollNudge/>
            </SectionTransition>
          </div>
          <div style={{backgroundColor: '#5F6260', padding: '2vh 0'}}>
            <ProjectSection title={'Faith Walk'} seeMoreLink={'https://faithwalk.app'} seeMoreText={'https://faithwalk.app'}>
              <ProjectSectionTextBlock>
                <div>A location-based Progressive Web App that provides immersive, 360 degree content.</div>
                <div>This project was quite the learning experience - everything from React, to Geolocation API's, to composing spatial audio.</div>
                <div>I hope to add more to this down the road. Feel free to check it out.</div>
              </ProjectSectionTextBlock>
              <ProjectSectionPicture  projectName={'Faith Walk'}/>
            </ProjectSection>
          </div>
        </LayoutStyles>
      </Layout>
    </div>
  )
}

export default Projects