import React from "react"
import ProjectSectionStyles from "./ProjectSectionStyles"
import ProjectSectionTitle from "./ProjectSectionTitle/ProjectSectionTitle"
import { Link } from "gatsby"

const ProjectSection = ({children, title, seeMoreLink, seeMoreText}) => {
  return (
    <ProjectSectionStyles>
      <ProjectSectionTitle>{title}</ProjectSectionTitle>
      <div className={`project-section-detail`}>
        {children}
      </div>
      {seeMoreLink && <Link to={seeMoreLink}><div className={'project-section-more'}>{seeMoreText || 'See More'}</div></Link> }
    </ProjectSectionStyles>
  )
}

export default ProjectSection