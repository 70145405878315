import React from "react"

const ProjectSectionTextBlock = ({children}) => {
  return (
    <div className={`project-section-detail-textblock`}>
      {children}
    </div>
  )
}

export default ProjectSectionTextBlock