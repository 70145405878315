import React from "react"
import { graphql, StaticQuery } from "gatsby"
import BackgroundImage from "gatsby-background-image"

const ProjectSectionPicture = ({ children, className, projectName }) => (
  <StaticQuery
    query={graphql`
      query {
        faithWalk: file(relativePath: { eq: "map-markers.jpg"}) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 1440) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        },
      }
  `}

    render={data => {
      //Set Image Data.
      let imageData = null;
      switch (projectName) {
        case 'Faith Walk':
          imageData = data.faithWalk.childImageSharp.fluid
          break;
        default:
          console.log(`Hit default case in image data, Project Name received was ${projectName}`)
          break;
      }

      return (
        <BackgroundImage
          Tag={'div'}
          className={'project-section-picture ' + className}
          fluid={imageData}
          backgroundColor={`#BFBAAD`}
        >
          {children}
        </BackgroundImage>
      )
    }}
  />
)

export default ProjectSectionPicture